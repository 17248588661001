import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";

export default class Collapse extends Element {
	mounted() {
		this.trigger = this.el.querySelector(".c-collapse__header");
		this.button = this.el.querySelector(".c-collapse__button");
		this.content = this.el.querySelector(".c-collapse__content");
		this.container = this.el.querySelector(".c-collapse__inner");

		this.setHeight();
		this.disableContent();

		if (this.el.classList.contains("is-open")) {
			this.setOpenState();
		} else {
			this.setClosedState();
		}
	}

	events() {
		this.trigger.addEventListener("click", (e) => this.onClick(e));
		this.resizeObserver = new ResizeObserver(() => this.onResize());
		this.resizeObserver.observe(this.el);
	}

	onClick(e) {
		e.preventDefault();
		this.setState();
	}

	setState() {
		if (!this.isOpen) {
			this.setHeight();
			this.setOpenState();
			this.enableContent();
		} else {
			this.unsetHeight();
			this.setClosedState();
			this.disableContent();
		}

		if (this.el.dataset.accordionIndex) {
			EventEmitter.emit(EVENTS.COLLAPSE_CLICK, this.el.dataset.accordionIndex);
		}
	}

	setOpenState() {
		this.el.classList.add("is-open");
		this.button.setAttribute("aria-expanded", "true");
		this.isOpen = true;
	}

	setClosedState() {
		this.el.classList.remove("is-open");
		this.button.setAttribute("aria-expanded", "false");
		this.isOpen = false;
	}

	disableContent() {
		// Fetch possible interactive content and update aria label
		const els = this.content.querySelectorAll(
			'button:not([disabled]),[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)'
		);

		els.forEach((el) => {
			el.tabIndex = -1;
			el.setAttribute("aria-hidden", "true");
		});
	}

	enableContent() {
		// Fetch possible interactive content and update aria label
		const els = this.content.querySelectorAll(
			'button:not([disabled]),[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)'
		);

		els.forEach((el) => {
			el.tabIndex = 0;
			el.setAttribute("aria-hidden", "false");
		});
	}

	getHeight() {
		if (this.container !== null) {
			const { height } = this.container.getBoundingClientRect();

			return height;
		}

		return 0;
	}

	setHeight() {
		const height = this.getHeight();

		this.el.style.setProperty("--c-collapse-height", `${height}px`);
	}

	unsetHeight() {
		this.el.style.setProperty("--c-collapse-height", 0);
	}

	onResize() {
		this.getHeight();
		this.setHeight();
	}

	getIndex() {
		return this.el.dataset.accordionIndex;
	}
}

