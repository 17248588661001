import Swiper from 'swiper';
import {Autoplay, Navigation, Pagination, EffectFade} from 'swiper/modules';

import {Element} from "@/scripts/extends";

export default class Slider extends Element {
	mounted() {
		this.swiper = this.el.swiper;
		this.container = this.el.querySelector(".c-slider__container");
		this.nextButton = this.el.querySelector(".c-slider__button--next");
		this.prevButton = this.el.querySelector(".c-slider__button--prev");

		if (this.container.classList.contains("swiper")) {
			Swiper.use([Autoplay, Navigation, Pagination, EffectFade]);

			this.setSwiper();
		}
	}

	setSwiper() {
		let customConfig;
		if (this.container.hasAttribute('data-swiper-config')) {
			customConfig = JSON.parse(this.container.dataset.swiperConfig);
		}

		const swiperConfig = {
			slidesPerView: 1,
			wrapperClass: "c-slider__wrapper",
			slideActiveClass: "is-active",
			slideClass: "c-slider__slide",
			navigation: {
				nextEl: this.nextButton,
				prevEl: this.prevButton,
			},
			pagination: {
				el: '.c-slider__pagination',
				bulletClass: 'c-slider__pagination-bullet',
				bulletActiveClass: 'c-slider__pagination-bullet-active',
				clickableClass: 'c-slider__pagination-bullet-clickable',
				horizontalClass: 'c-slider__pagination-horizontal',
				clickable: true,
			},
		};

		const mergedConfig = {
			...swiperConfig,
			...customConfig
		};
		
		this.swiper = new Swiper(this.container, mergedConfig);

		this.swiper.init();
	}
}

