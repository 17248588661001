import { Element } from "@/scripts/extends";
import { EventEmitter } from "@/scripts/core";
import { EVENTS } from "@/scripts/constants";
import Collapse from "./Collapse";

export default class Accordion extends Element {
	mounted() {
		this.name = this.el.getAttribute("data-accordion-name");
		this.items = this.el.querySelectorAll(".c-accordion__item");
		this.els = this.el.querySelectorAll(`[data-accordion-item="${this.name}"]`);

		this.collapses = [...this.els].map((el) => new Collapse(el));
		this.activeIndex = 0;
	}

	events() {
		EventEmitter.on(EVENTS.COLLAPSE_CLICK, (e) => this.handleCollapses(e));
	}

	handleCollapses(e) {
		this.activeIndex = e;

		this.handleDisableCollapses();
	}

	handleDisableCollapses() {
		this.collapses.forEach((collapse) => {
			const index = collapse.getIndex();

			if (index !== this.activeIndex) {
				collapse.setClosedState();
			}
		});
	}
}